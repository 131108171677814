"use strict";

var _toConsumableArray = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20250113164532/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.roundToDecimals = function (decimalsCount) {
  return function (numberToRound) {
    return exports.applyToNDecimals(Math.round)(decimalsCount)(numberToRound);
  };
};

exports.roundToTwoDecimals = exports.roundToDecimals(2);

exports.sum = function (a, b) {
  return a + b;
};

exports.sumArray = function (a) {
  return a.reduce(function (acc, item) {
    return acc + item;
  }, 0);
};

exports.floorToDecimals = function (decimalsCount) {
  return function (numberToRound) {
    return exports.applyToNDecimals(Math.floor)(decimalsCount)(numberToRound);
  };
};

exports.floorToTwoDecimals = exports.floorToDecimals(2);

exports.applyToNDecimals = function (mathFn) {
  return function (decimalsCount) {
    return function (numberToApply) {
      var multiplier = Math.pow(10, decimalsCount);
      return mathFn(multiplier * numberToApply) / multiplier;
    };
  };
};

exports.maxFromArray = function (a) {
  return Math.max.apply(Math, _toConsumableArray(a));
};