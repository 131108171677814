"use strict";

var _toConsumableArray = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20250113164532/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});

function sort(list) {
  var fn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultSort;
  return _toConsumableArray(list).sort(fn);
}

exports.sort = sort;

function sortByAttribute(list, attributeName, fn) {
  return sort(list, function (a, b) {
    return fn(a[attributeName], b[attributeName]);
  });
}

exports.sortByAttribute = sortByAttribute;

exports.idx = function (propertyList, object) {
  return propertyList.reduce(function (currentObject, key) {
    return currentObject && currentObject[key] ? currentObject[key] : null;
  }, object);
};

function defaultSort(a, b) {
  return a > b ? -1 : 1;
}