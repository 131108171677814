"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20250113164532/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$SAXOPRINT_PR, _exports$SAXOPRINT_PR2, _exports$SAXOPRINT_PR3;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

var errors_1 = require("../../../errors");

function getSaxoprintUUIDForProductCode(config) {
  switch (config.productCode) {
    case types_1.SAXOPRINT_PRODUCT_CODES.FLYER:
      return '08f118c5-4bd9-11e9-b981-02e0dafd9374';

    case types_1.SAXOPRINT_PRODUCT_CODES.DESK_CALENDAR:
      return config.quantity < 100 ? '08f162d9-4bd9-11e9-b981-02e0dafd9374' : '08f16407-4bd9-11e9-b981-02e0dafd9374';

    case types_1.SAXOPRINT_PRODUCT_CODES.WALL_CALENDAR:
      return config.quantity < 100 ? '08f16239-4bd9-11e9-b981-02e0dafd9374' : '08f1628f-4bd9-11e9-b981-02e0dafd9374';
  }

  return errors_1.assertNever(config.productCode);
}

exports.getSaxoprintUUIDForProductCode = getSaxoprintUUIDForProductCode;

function getSaxoprintExternalIdForConfig(config) {
  switch (config.productCode) {
    case types_1.SAXOPRINT_PRODUCT_CODES.FLYER:
      return 1;

    case types_1.SAXOPRINT_PRODUCT_CODES.DESK_CALENDAR:
      return config.quantity < 100 ? 18 : 851;

    case types_1.SAXOPRINT_PRODUCT_CODES.WALL_CALENDAR:
      return config.quantity < 100 ? 14 : 850;
  }

  return errors_1.assertNever(config.productCode);
}

exports.getSaxoprintExternalIdForConfig = getSaxoprintExternalIdForConfig; // Flyer

var FLYER_CATEGORY_ID_BY_PROPERTY = {
  productGroupId: '6',
  finishedSizeId: '9',
  sideCountId: '10',
  colourModeId: '7',
  materialId: '8',
  finishingId: '35',
  finishingSideId: '43',
  finishingEffectId: '42'
};
var FLYER_NULLABLE_PROPERTY_NAMES = ['finishingSideId', 'finishingEffectId']; // Calendriers de bureau

var DESK_CALENDAR_CATEGORY_ID_BY_PROPERTY = {
  productGroupId: '6',
  finishedSizeId: '9',
  sideCountId: '10',
  colourModeId: '7',
  materialId: '8',
  bindingTypeId: '34',
  finishingId: '35',
  finishingEffectId: '42',
  finishingSideId: '43'
};
var DESK_CALENDAR_NULLABLE_PROPERTY_NAMES = ['finishingId', 'finishingEffectId', 'finishingSideId']; // Calendriers muraux

var WALL_CALENDAR_CATEGORY_ID_BY_PROPERTY = {
  productGroupId: '6',
  finishedSizeId: '9',
  sideCountId: '10',
  colourModeId: '7',
  materialId: '8',
  bindingTypeId: '34',
  pvcCoverId: '92',
  processingId: '45',
  finishingId: '35',
  finishingSideId: '43',
  finishingEffectId: '42'
};
var WALL_CALENDAR_NULLABLE_PROPERTY_NAMES = ['finishingId', 'finishingSideId', 'finishingEffectId'];
exports.SAXOPRINT_PRODUCTS_CATEGORY_ID_BY_PROPERTY = (_exports$SAXOPRINT_PR = {}, _defineProperty(_exports$SAXOPRINT_PR, types_1.SAXOPRINT_PRODUCT_CODES.FLYER, FLYER_CATEGORY_ID_BY_PROPERTY), _defineProperty(_exports$SAXOPRINT_PR, types_1.SAXOPRINT_PRODUCT_CODES.DESK_CALENDAR, DESK_CALENDAR_CATEGORY_ID_BY_PROPERTY), _defineProperty(_exports$SAXOPRINT_PR, types_1.SAXOPRINT_PRODUCT_CODES.WALL_CALENDAR, WALL_CALENDAR_CATEGORY_ID_BY_PROPERTY), _exports$SAXOPRINT_PR);
exports.SAXOPRINT_PRODUCTS_PROPERTY_NAME_FOR_EXTERNAL_ID = (_exports$SAXOPRINT_PR2 = {}, _defineProperty(_exports$SAXOPRINT_PR2, types_1.SAXOPRINT_PRODUCT_CODES.FLYER, getProductPropertyNameForExternalId(types_1.SAXOPRINT_PRODUCT_CODES.FLYER)), _defineProperty(_exports$SAXOPRINT_PR2, types_1.SAXOPRINT_PRODUCT_CODES.DESK_CALENDAR, getProductPropertyNameForExternalId(types_1.SAXOPRINT_PRODUCT_CODES.DESK_CALENDAR)), _defineProperty(_exports$SAXOPRINT_PR2, types_1.SAXOPRINT_PRODUCT_CODES.WALL_CALENDAR, getProductPropertyNameForExternalId(types_1.SAXOPRINT_PRODUCT_CODES.WALL_CALENDAR)), _exports$SAXOPRINT_PR2);
exports.SAXOPRINT_PRODUCTS_NULLABLE_PROPERTY_NAMES = (_exports$SAXOPRINT_PR3 = {}, _defineProperty(_exports$SAXOPRINT_PR3, types_1.SAXOPRINT_PRODUCT_CODES.FLYER, FLYER_NULLABLE_PROPERTY_NAMES), _defineProperty(_exports$SAXOPRINT_PR3, types_1.SAXOPRINT_PRODUCT_CODES.DESK_CALENDAR, DESK_CALENDAR_NULLABLE_PROPERTY_NAMES), _defineProperty(_exports$SAXOPRINT_PR3, types_1.SAXOPRINT_PRODUCT_CODES.WALL_CALENDAR, WALL_CALENDAR_NULLABLE_PROPERTY_NAMES), _exports$SAXOPRINT_PR3);

function getProductPropertyNameForExternalId(productCode) {
  var categoryIdByProperty = exports.SAXOPRINT_PRODUCTS_CATEGORY_ID_BY_PROPERTY[productCode];
  return Object.keys(categoryIdByProperty).reduce(function (acc, curr) {
    return _objectSpread({}, acc, _defineProperty({}, categoryIdByProperty[curr], curr));
  }, {});
}