"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20250113164532/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$totebagTrans, _exports$CALCULATORS_, _exports$CUT_TYPE_TRA, _exports$EYELET_INTER, _exports$FINISHED_PRO, _exports$FLEXIBLE_EYE, _exports$MATERIAL_TYP, _exports$PERFORATION_, _exports$PRINTING_TYP, _exports$PRODUCTION_M, _exports$RIGID_EYELET, _exports$SHIPPING_MOD, _exports$TRANSPORTER_, _exports$WHITE_LAYER_, _exports$PRODUCTION_M2, _exports$BUNDLE_TYPE_;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

var constants_1 = require("./constants");

exports.totebagTranslations = (_exports$totebagTrans = {}, _defineProperty(_exports$totebagTrans, constants_1.COLOR_TYPE_ECRU, 'Ecru'), _defineProperty(_exports$totebagTrans, constants_1.COLOR_TYPE_COLORED, 'Coloré'), _defineProperty(_exports$totebagTrans, constants_1.PRINTING_MODE_ONE_SIDED, 'Recto'), _defineProperty(_exports$totebagTrans, constants_1.PRINTING_MODE_TWO_SIDED, 'Recto/Verso'), _defineProperty(_exports$totebagTrans, constants_1.INK_TYPE_QUADRI, 'Quadri / numérique'), _defineProperty(_exports$totebagTrans, constants_1.INK_TYPE_SILKSCREEN, 'Sérigraphie'), _defineProperty(_exports$totebagTrans, constants_1.PRINTING_LEVEL_QUADRI_ZONE_15_20, "Zone d'impression 15x20"), _defineProperty(_exports$totebagTrans, constants_1.PRINTING_LEVEL_QUADRI_ZONE_20_30, "Zone d'impression 20x30"), _defineProperty(_exports$totebagTrans, constants_1.PRINTING_LEVEL_QUADRI_ZONE_37_37, "Zone d'impression 37x37"), _defineProperty(_exports$totebagTrans, constants_1.PRINTING_LEVEL_SILKSCREEN_ONE_COLOR, '1 couleur'), _defineProperty(_exports$totebagTrans, constants_1.PRINTING_LEVEL_SILKSCREEN_TWO_COLORS, '2 couleurs'), _defineProperty(_exports$totebagTrans, constants_1.PRINTING_LEVEL_SILKSCREEN_THREE_COLORS, '3 couleurs'), _exports$totebagTrans);
exports.CALCULATORS_TRANSLATIONS = (_exports$CALCULATORS_ = {}, _defineProperty(_exports$CALCULATORS_, types_1.CALCULATORS.FLEXIBLE, 'Souple'), _defineProperty(_exports$CALCULATORS_, types_1.CALCULATORS.RIGID, 'Rigide'), _defineProperty(_exports$CALCULATORS_, types_1.CALCULATORS.KAKEMONO, 'Kakémono'), _defineProperty(_exports$CALCULATORS_, types_1.CALCULATORS.TOTEBAG, 'Totebag'), _defineProperty(_exports$CALCULATORS_, types_1.CALCULATORS.FINISHED_PRODUCT, 'Rollup & Banner'), _exports$CALCULATORS_);
exports.CUT_TYPE_TRANSLATIONS = (_exports$CUT_TYPE_TRA = {}, _defineProperty(_exports$CUT_TYPE_TRA, types_1.CUT_TYPES.STRAIGHT, 'Droite'), _defineProperty(_exports$CUT_TYPE_TRA, types_1.CUT_TYPES.SHAPE, 'A la forme'), _exports$CUT_TYPE_TRA);
exports.EYELET_INTERVAL_TRANSLATIONS = (_exports$EYELET_INTER = {}, _defineProperty(_exports$EYELET_INTER, types_1.EYELET_INTERVALS.TEN, '10 cm'), _defineProperty(_exports$EYELET_INTER, types_1.EYELET_INTERVALS.TWENTY_FIVE, '25 cm'), _defineProperty(_exports$EYELET_INTER, types_1.EYELET_INTERVALS.FIFTY, '50 cm'), _defineProperty(_exports$EYELET_INTER, types_1.EYELET_INTERVALS.HUNDRED, '100 cm'), _exports$EYELET_INTER);
exports.FINISHED_PRODUCT_CATEGORIES_TRANSLATIONS = (_exports$FINISHED_PRO = {}, _defineProperty(_exports$FINISHED_PRO, types_1.FINISHED_PRODUCT_CATEGORIES.ROLLUP, 'Rollup'), _defineProperty(_exports$FINISHED_PRO, types_1.FINISHED_PRODUCT_CATEGORIES.XBANNER, 'X-banner'), _defineProperty(_exports$FINISHED_PRO, types_1.FINISHED_PRODUCT_CATEGORIES.OUTDOOR, 'Extérieur'), _exports$FINISHED_PRO);
exports.FLEXIBLE_EYELET_TYPE_TRANSLATIONS = (_exports$FLEXIBLE_EYE = {}, _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.NONE, '-'), _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.FOUR_ANGLES, '4 angles'), _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.PERIMETER, 'Périmètre'), _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.UP_DOWN, 'Haut et Bas'), _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.UP_TWO_ANGLES, '2 angles supérieurs'), _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.UP, 'En haut'), _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.DOWN, 'En bas'), _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.LEFT, 'Côté gauche'), _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.RIGHT, 'Côté droit'), _defineProperty(_exports$FLEXIBLE_EYE, types_1.EYELET_TYPES.LEFT_RIGHT, 'Côtés gauche et droit'), _exports$FLEXIBLE_EYE);
exports.MATERIAL_TYPE_TRANSLATIONS = (_exports$MATERIAL_TYP = {}, _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_TAPE, 'Adhésif'), _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_TARP, 'Bâche'), _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_PAPER, 'Papier'), _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_MAGNETIC, 'Magnétique'), _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_FLEXIBLE_PVC, 'PVC'), _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_AKILUX, 'Akilux'), _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_CARDBOARD, 'Carton'), _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_DIBOND, 'Dibond'), _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_PLEXI, 'Plexi'), _defineProperty(_exports$MATERIAL_TYP, constants_1.TYPE_RIGID_PVC, 'PVC'), _exports$MATERIAL_TYP);
exports.PERFORATION_TYPE_TRANSLATIONS = (_exports$PERFORATION_ = {}, _defineProperty(_exports$PERFORATION_, types_1.PERFORATION_TYPES.UP, '2 perforations (haut)'), _defineProperty(_exports$PERFORATION_, types_1.PERFORATION_TYPES.FOUR_ANGLES, '4 perforations (4 angles)'), _defineProperty(_exports$PERFORATION_, types_1.PERFORATION_TYPES.UP_DOWN, '6 perforations (3 haut / 3 bas)'), _defineProperty(_exports$PERFORATION_, types_1.PERFORATION_TYPES.LEFT_RIGHT, '6 perforations (3 gauche / 3 droite)'), _exports$PERFORATION_);
exports.PRINTING_TYPE_TRANSLATIONS = (_exports$PRINTING_TYP = {}, _defineProperty(_exports$PRINTING_TYP, types_1.PRINTING_TYPES.ONE_SIDED, 'Recto'), _defineProperty(_exports$PRINTING_TYP, types_1.PRINTING_TYPES.TWO_SIDED, 'Recto/Verso'), _defineProperty(_exports$PRINTING_TYP, types_1.PRINTING_TYPES.TWO_SIDED_SAME, 'Recto/Verso identiques'), _defineProperty(_exports$PRINTING_TYP, types_1.PRINTING_TYPES.TWO_SIDED_DIFFERENT, 'Recto/Verso différents'), _defineProperty(_exports$PRINTING_TYP, types_1.PRINTING_TYPES.NONE, "Sans"), _defineProperty(_exports$PRINTING_TYP, types_1.PRINTING_TYPES.MIRROR, "Miroir"), _exports$PRINTING_TYP);
exports.PRODUCTION_MODE_TRANSLATIONS = (_exports$PRODUCTION_M = {}, _defineProperty(_exports$PRODUCTION_M, types_1.PRODUCTION_MODES.STANDARD, "Standard"), _defineProperty(_exports$PRODUCTION_M, types_1.PRODUCTION_MODES.EXPRESS, "Express"), _defineProperty(_exports$PRODUCTION_M, types_1.PRODUCTION_MODES.OVERNIGHT, "Rush"), _exports$PRODUCTION_M);
exports.RIGID_EYELET_TYPE_TRANSLATIONS = (_exports$RIGID_EYELET = {}, _defineProperty(_exports$RIGID_EYELET, types_1.EYELET_TYPES.NONE, '-'), _defineProperty(_exports$RIGID_EYELET, types_1.EYELET_TYPES.FOUR_ANGLES, '4 oeillets (4 angles)'), _defineProperty(_exports$RIGID_EYELET, types_1.EYELET_TYPES.PERIMETER, 'Périmètre'), _defineProperty(_exports$RIGID_EYELET, types_1.EYELET_TYPES.UP, '2 oeillets (haut)'), _defineProperty(_exports$RIGID_EYELET, types_1.EYELET_TYPES.UP_DOWN, '6 oeillets (3 haut / 3 bas)'), _defineProperty(_exports$RIGID_EYELET, types_1.EYELET_TYPES.LEFT_RIGHT, '6 oeillets (3 gauche / 3 droite)'), _exports$RIGID_EYELET);
exports.SHIPPING_MODE_TRANSLATIONS = (_exports$SHIPPING_MOD = {}, _defineProperty(_exports$SHIPPING_MOD, types_1.SHIPPING_MODES.TRANSPORTER, "Transporteur"), _defineProperty(_exports$SHIPPING_MOD, types_1.SHIPPING_MODES.COURIER, "Coursier"), _defineProperty(_exports$SHIPPING_MOD, types_1.SHIPPING_MODES.PICKUP, "Pas de livraison"), _exports$SHIPPING_MOD);
exports.TRANSPORTER_TRANSLATIONS = (_exports$TRANSPORTER_ = {}, _defineProperty(_exports$TRANSPORTER_, types_1.TRANSPORTERS.NO_TRANSPORTER, "Pas de transporteur"), _defineProperty(_exports$TRANSPORTER_, types_1.TRANSPORTERS.DPD, "DPD"), _defineProperty(_exports$TRANSPORTER_, types_1.TRANSPORTERS.CHRONOPOST, "Chronopost"), _defineProperty(_exports$TRANSPORTER_, types_1.TRANSPORTERS.PALLET, "FranceExpress"), _defineProperty(_exports$TRANSPORTER_, types_1.TRANSPORTERS.COURIER, "Coursier"), _defineProperty(_exports$TRANSPORTER_, types_1.TRANSPORTERS.SCHENKER, "Schenker"), _defineProperty(_exports$TRANSPORTER_, types_1.TRANSPORTERS.TNT_13, "TNT 13"), _defineProperty(_exports$TRANSPORTER_, types_1.TRANSPORTERS.TNT_18, "TNT 18"), _exports$TRANSPORTER_);
exports.WHITE_LAYER_TYPE_TRANSLATIONS = (_exports$WHITE_LAYER_ = {}, _defineProperty(_exports$WHITE_LAYER_, types_1.WHITE_LAYER_TYPES.NONE, 'Sans'), _defineProperty(_exports$WHITE_LAYER_, types_1.WHITE_LAYER_TYPES.SELECTIVE_WHITE, 'Blanc seul'), _defineProperty(_exports$WHITE_LAYER_, types_1.WHITE_LAYER_TYPES.SELECTIVE, 'Sélectif + quadri'), _defineProperty(_exports$WHITE_LAYER_, types_1.WHITE_LAYER_TYPES.TOTAL, 'Total + quadri'), _defineProperty(_exports$WHITE_LAYER_, types_1.WHITE_LAYER_TYPES.SELECTIVE_MIRROR, 'Blanc seul Miroir'), _defineProperty(_exports$WHITE_LAYER_, types_1.WHITE_LAYER_TYPES.TOTAL_MIRROR, 'Total Miroir'), _exports$WHITE_LAYER_); //Used for new prices in finished products

exports.PRODUCTION_MODE_SHORTCUTS = (_exports$PRODUCTION_M2 = {}, _defineProperty(_exports$PRODUCTION_M2, types_1.PRODUCTION_MODES.STANDARD, 'Std'), _defineProperty(_exports$PRODUCTION_M2, types_1.PRODUCTION_MODES.EXPRESS, 'Exp'), _defineProperty(_exports$PRODUCTION_M2, types_1.PRODUCTION_MODES.OVERNIGHT, 'Exp'), _exports$PRODUCTION_M2);
exports.BUNDLE_TYPE_SHORTCUTS = (_exports$BUNDLE_TYPE_ = {}, _defineProperty(_exports$BUNDLE_TYPE_, types_1.BUNDLE_TYPES.FULL, 'full'), _defineProperty(_exports$BUNDLE_TYPE_, types_1.BUNDLE_TYPES.MATERIAL_ONLY, 'print'), _defineProperty(_exports$BUNDLE_TYPE_, types_1.BUNDLE_TYPES.STRUCTURE_ONLY, 'structure'), _exports$BUNDLE_TYPE_);